import React from 'react';
import dateFormat from 'dateformat';

import LoadingIndicator from '@deliveroo/consumer-component-library/components/LoadingIndicator';
import BlogPost from '../BlogPost';
import styles from './styles.module.scss';

function stripHTML(str) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || '';
}

function getExcerpt(str) {
  return `${str.slice(0, str.lastIndexOf(' ', 80))}…`;
}

function formatDate(isoDate) {
  return dateFormat(Date.parse(isoDate), 'mmmm dS, yyyy');
}

function getMediumPosts() {
  const { parse } = require('rss-to-json');

  return parse('https://corsproxy.io/?https://medium.com/feed/deliveroo-design')
    .then(({ items }) =>
        items
          .map((item) => ({
            title: stripHTML(item.title),
            author: item.author,
            image: stripHTML(item.content.toString().match(/<img[^>]+src="([^">]+)"/)[1]),
            link: item.link,
            date: formatDate(item.pubDate),
            description: getExcerpt(stripHTML(item.content)),
          }))
          .slice(0, 6),
      );
}

class BlogPostsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      didError: false,
      posts: [],
    };
  }

  componentDidMount() {
    getMediumPosts()
      .then((posts) => {
        this.setState({
          isLoading: false,
          posts,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          didError: true,
        });
        console.error(err); // eslint-disable-line no-console
      });
  }

  renderContent() {
    if (this.state.isLoading) {
      return (
        <div className={styles.loadingWrapper}>
          <LoadingIndicator size={24} />
        </div>
      );
    }

    if (this.state.didError) {
      return (
        <div>
          {"We couldn't load the blog. Refresh the page or come back later."}
        </div>
      );
    }

    return (
      <div className={styles.postsWrapper}>
        {this.state.posts.map((post) => (
          <div className={styles.post} key={post.title}>
            <BlogPost {...post} />
          </div>
        ))}
      </div>
    );
  }

  render() {
    return <div className={styles.wrapper}>{this.renderContent()}</div>;
  }
}

export default BlogPostsGrid;
