import React from 'react';
import PropTypes from 'prop-types';

import Heading from '@deliveroo/consumer-component-library/components/Heading';
import BackgroundImage from '@deliveroo/consumer-component-library/components/BackgroundImage';
import P from '@deliveroo/consumer-component-library/components/P';
import Span from '@deliveroo/consumer-component-library/components/Span';

import styles from './styles.module.scss';

const BlogPost = ({ title, author, date, image, link, description }) => (
  <a
    className={styles.post}
    href={link}
    target="blank"
    rel="noopener noreferrer"
  >
    <div>
      <div className={styles.image}>
        <BackgroundImage src={image} />
      </div>

      <div className={styles.content}>

        <Heading spacing="medium 0 0 0" level={4} size="small">
          <span className={styles.title}>{title}</span>
        </Heading>


      </div>
    </div>
    <div className={styles.metadata}>
      <Span color="grey" size="small" isBlock>
        {author} ・ {date}
      </Span>
    </div>

  </a>
);

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default BlogPost;
