import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Section from '../components/Section';
import CenteredContainer from '../components/CenteredContainer';
import TeamRow from '../components/TeamRow';
import SectionHeading from '../components/SectionHeading';
import PeopleGrid from '../components/PeopleGrid';
import PersonCard from '../components/PersonCard';
import BlogPostsGrid from '../components/BlogPostsGrid';
import CareersSection from '../components/CareersSection';

const PagesIndex = ({ data }) => (
  <div>
    <Header
      additionalMarkdown={data.header.html}
      titles={data.header.frontmatter.heroTitles}
    />
    <Section>
      {data.teams.edges.map(({ node: team }, index, { length }) => (
        <TeamRow
          key={team.id}
          image={team.frontmatter.image[0].childImageSharp.fixed.src}
          title={team.frontmatter.title}
          markdown={team.html}
          isFlipped={index % 2 !== 0}
          hasMargin={index !== length - 1}
        />
      ))}
    </Section>

    <Section background="white">
      <CenteredContainer>
        <SectionHeading
          title={data.blogSectionHeading.frontmatter.title}
          markdown={data.blogSectionHeading.html}
        />
        <BlogPostsGrid />
      </CenteredContainer>
    </Section>

    <Section>
      <CenteredContainer>
        <SectionHeading
          title={data.peopleSectionHeading.frontmatter.title}
          markdown={data.peopleSectionHeading.html}
        />
        <PeopleGrid>
          {data.people.edges
            .sort(() => 0.5 - Math.random())
            .map(({ node: person }) => (
              <PersonCard
                key={person.id}
                name={person.frontmatter.name}
                role={person.frontmatter.role}
                image={person.frontmatter.image[0].childImageSharp.fixed.src}
              />
            ))}
        </PeopleGrid>
      </CenteredContainer>
    </Section>

    <Section background="white" id="jobs">
      <CareersSection
        title={data.careersSectionHeading.frontmatter.title}
        markdown={data.careersSectionHeading.html}
      />

      <Footer />
    </Section>
  </div>
);

/**
 * We don't check prop types here since they are checked further
 * downstream by other components.
 */
PagesIndex.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export const query = graphql`
  query IndexQuery {
    header: markdownRemark(fields: { path: { eq: "/fragments/header/" } }) {
      html
      frontmatter {
        heroTitles
      }
    }
    teams: allMarkdownRemark(
      filter: { fields: { path: { regex: "/teams//" } } }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fixed(width: 1120, height: 860, cropFocus: CENTER) {
                  src
                }
              }
            }
          }
          html
        }
      }
    }
    people: allMarkdownRemark(
      filter: { fields: { path: { regex: "/people//" } } }
      sort: { fields: [frontmatter___name] }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            role
            image {
              childImageSharp {
                fixed(width: 200, height: 200, cropFocus: NORTH, fit: COVER) {
                  src
                }
              }
            }
          }
          html
        }
      }
    }
    blogSectionHeading: markdownRemark(
      fields: { path: { eq: "/fragments/blog-section-heading/" } }
    ) {
      frontmatter {
        title
      }
      html
    }
    peopleSectionHeading: markdownRemark(
      fields: { path: { eq: "/fragments/people-section-heading/" } }
    ) {
      frontmatter {
        title
      }
      html
    }
    careersSectionHeading: markdownRemark(
      fields: { path: { eq: "/fragments/careers-section-heading/" } }
    ) {
      frontmatter {
        title
      }
      html
    }
  }
`;

export default PagesIndex;
